import { checkConsentForPurpose } from "./integration/didomi";
async function personalizedTeaser() {
  const autoteaser = window.document.querySelector(".teaser[data-autoteaser=true]");
  if (!autoteaser) return;
  const hasConsent = await new Promise(resolve => checkConsentForPurpose("marketing", resolve));
  const dataPosition = autoteaser.dataset.position;
  const url = new URL("/poof", window.location.origin);
  url.searchParams.append("pathname", window.location.pathname);
  url.searchParams.append("hasConsent", hasConsent);
  url.searchParams.append("location", dataPosition);
  const response = await fetch(url.href, {
    credentials: "same-origin"
  });
  if (response.status !== 200) return;
  const personalizedContent = await response.text();
  const template = document.createElement("template");
  template.innerHTML = personalizedContent;
  autoteaser.replaceChildren(...template.content.querySelector(".teaser").children);
}
personalizedTeaser();